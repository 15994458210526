<template>
	<div class="login-container">
		<div class="logo" @click="gotoHome"></div>
		<!-- <div class="name">建筑企业经营决策支持平台</div> -->
		<div class="name">建筑企业经营决策支持平台</div>
		<div class="form-box">
			<div class="tab-box">
				<el-tabs v-model="activeName" @tab-click="handleClick">
					<el-tab-pane label="手机快捷登录" name="phone">
						<div class="main-content">
							<div class="explain">未注册账号的手机号，登录时将自动注册</div>
							<div class="loin-form">
								<el-form ref="dataPO" :model="dataPO" :rules="rules">
									<el-form-item prop="phone">
										<el-input v-model="dataPO.phone" oninput="value=value.replace(/[^\d]/g,'')" @blur="dataPO.phone = $event.target.value" placeholder="您的手机号" prefix-icon="iconfont icon-shouji"></el-input>
									</el-form-item>
									<el-form-item prop="code">
										<div class="code-form-box">
											<el-input class="code-form" v-model="dataPO.code" placeholder="请输入验证码" prefix-icon="iconfont icon-suo"></el-input>
											<div class="get-code" @click.prevent="getCode()">
												{{ codeText }}
											</div>
										</div>
									</el-form-item>
								</el-form>
							</div>
							<div class="agree-box">
								<el-checkbox v-model="agreeChecked"></el-checkbox>
								<span class="content">我已阅读并同意</span>
								<span class="agree" @click="openAgree">《注册协议》</span>
								<span style="font-size: 0.73vw;">、</span>
								<span class="agree" @click="openPrivacy">《隐私政策》</span>
							</div>
							<div class="submit-form" @click.prevent="submitForm">
								立即登录
							</div>
						</div>
					</el-tab-pane>
					<el-tab-pane label="手机扫码登录" name="code">
						<div class="code-img">
							<img @dragstart.prevent :src="qrCode" title="点击刷新" @click="refreshQrCode" />
							<div class="desc-text" v-if="mask">扫码前请勾选协议</div>
							<div class="code-text">打开慧决策APP/小程序扫码登录</div>
							<div class="mask" v-show="mask">
								<div class="gantan"></div>
							</div>
						</div>
						<div class="agree-code-box">
							<el-checkbox v-model="agreeCodeChecked"></el-checkbox>
							<span class="content">我已阅读并同意</span>
							<span class="agree" @click="openAgree">《注册协议》</span>
							<span style="font-size: 0.73vw;">、</span>
							<span class="agree" @click="openPrivacy">《隐私政策》</span>
						</div>
					</el-tab-pane>
				</el-tabs>
			</div>
			<div class="other-box">
				<div class="other-name">其他登录方式</div>
				<div class="img-box">
					<div class="wechat" @click="wxLogin()"></div>
					<div class="alipay" @click="aliLogin()"></div>
				</div>
			</div>
		</div>
		<div class="footer-box">
			<div class="footer">
				<div class="msg-box">
					<span>Copyright © 2022</span>
					<span class="address">安徽数智建造研究院有限公司版权所有 | 地址：安徽省合肥市包河区南二环与宿松南路交叉口北200米</span>
				</div>
				<div class="copy">皖ICP备19004658号 | 皖公网安备 11010502030143</div>
			</div>
		</div>
		<agreement-modal ref="agreementModal" :title="'用户注册协议'" :text="false"></agreement-modal>
		<div class="float-nav">
			<div class="app_box">APP</div>
			<div>下载</div>
			<div>APP</div>
			<div class="float_left animated fadeIn">
				<img src="../../../public/app.png" alt="" />
			</div>
		</div>
		<div class="float-nav-wx">
			<div>微信</div>
			<div>小程序</div>
			<div class="float_left_wx animated fadeIn">
				<img src="../../../public/wx.jpg" alt="" />
			</div>
		</div>
		<privacy-modal ref="privacyModal"></privacy-modal>
		<Vcode :show="isShow" @success="success" @close="close" />
	</div>
</template>

<script>
import AgreementModal from "@/components/agreementModal";
import privacyModal from "@/components/privacyModal";
import { doEncrypt } from "@/utils/smCrypto.js";
import Vcode from "vue-puzzle-vcode";
export default {
	components: {
		AgreementModal,
		privacyModal,
		Vcode,
	},
	data() {
		let checkPhone = (rule, value, callback) => {
			if (!value) {
				return callback(new Error("请输入手机号"));
			} else {
				const reg = "^((13[0-9])|(14[5,7])|(15[0-3,5-9])|(17[0,3,5-9])|(18[0-9])|166|198|199|191|195|193|(147))\\d{8}$";
				if (new RegExp(reg).test(value)) {
					callback();
				} else {
					return callback(new Error("请输入正确的手机号"));
				}
			}
		};
		return {
			status: "",
			key: "",
			qrCode: "",
			activeName: "phone",
			dataPO: {
				phone: "", //手机号
				code: "", // 验证码
				terminal: 1, // pc端
			},
			rules: {
				phone: [{validator: checkPhone, trigger: "blur"}],
				code: [{required: true, message: "请输入验证码", trigger: "blur"}],
			},
			codeText: "获取验证码",
			agreeChecked: false, //同意协议状态
			agreeCodeChecked: false, //扫码登录同意协议状态
			mask: true,
			isShow: false,
		};
	},
	mounted() {
		console.log(this.$VERSION, "this.$VERSION");
		console.log(this.$route.query);
	},
	watch: {
		agreeCodeChecked(val) {
			if (val) {
				this.mask = false;
			} else {
				this.mask = true;
			}
		},
	},
	methods: {
		//支付宝登录
		aliLogin() {
			if (this.activeName == "phone") {
				if (!this.agreeChecked) {
					this.$message.error("请勾选用户协议");
					return;
				}
			} else {
				if (!this.agreeCodeChecked) {
					this.$message.error("请勾选用户协议");
					return;
				}
			}
			this.thirdLogin();
			console.log(this.key);
			var name; //网页名称，可为空;
			var iWidth = 950; //弹出窗口的宽度;
			var iHeight = 500; //弹出窗口的高度;
			var iTop = (window.screen.height - 30 - iHeight) / 2; //获得窗口的垂直位置;
			var iLeft = (window.screen.width - iWidth) / 2; //获得窗口的水平位置  处于屏幕最左侧
			window.open(process.env.VUE_APP_BASE_URL + "/auth/api/aliLogin?state=" + this.key, name, "height=" + iHeight + ",innerHeight=" + iHeight + ",width=" + iWidth + ",innerWidth=" + iWidth + ",top=" + iTop + ",left=" + iLeft + ",toolbar=no,menubar=no,scrollbars=auto,resizable=no,location=no,status=no");
		},
		wxLogin() {
			if (this.activeName == "phone") {
				if (!this.agreeChecked) {
					this.$message.error("请勾选用户协议");
					return;
				}
			} else {
				if (!this.agreeCodeChecked) {
					this.$message.error("请勾选用户协议");
					return;
				}
			}
			this.thirdLogin();
			var name; //网页名称，可为空;
			var iWidth = 950; //弹出窗口的宽度;
			var iHeight = 500; //弹出窗口的高度;
			var iTop = (window.screen.height - 30 - iHeight) / 2; //获得窗口的垂直位置;
			var iLeft = (window.screen.width - iWidth) / 2; //获得窗口的水平位置  处于屏幕最左侧
			window.open("https://open.weixin.qq.com/connect/qrconnect?appid=wxb48c24d641f39adc&redirect_uri=https%3A%2F%2Fmds.ctcemti.com%2Fapi%2Fauth%2Fapi%2FwxLogin&state=" + this.key + "&response_type=code&scope=snsapi_login#wechat_redirect", name, "height=" + iHeight + ",innerHeight=" + iHeight + ",width=" + iWidth + ",innerWidth=" + iWidth + ",top=" + iTop + ",left=" + iLeft + ",toolbar=no,menubar=no,scrollbars=auto,resizable=no,location=no,status=no");
		},
		// 第三方登录
		thirdLogin() {
			this.key = this.$VERSION + "_$_" + new Date().getTime();
			if (this.status != "") {
				clearInterval(this.status);
				this.status = "";
			}
			let that = this;
			this.status = setInterval(function() {
				that.checkQrLoginStatus();
			}, 2000);
			this.$once("hook:beforeDestroy", () => {
				clearInterval(this.status);
				this.status = "";
			});
		},
		// 进入首页
		gotoHome() {
			this.$router.push("/home");
		},
		// 切换登录方式
		handleClick(tab, event) {
			console.log(tab, event);
			if (this.status != "") {
				clearInterval(this.status);
				this.status = "";
			}
			if (tab.index == 1) {
				this.key = new Date().getTime();
				this.qrCode = process.env.VUE_APP_BASE_URL + "/auth/api/refreshQrCode?key=" + this.key + "&deviceCode=" + this.$VERSION;
				let that = this;
				this.status = setInterval(function() {
					that.checkQrLoginStatus();
				}, 2000);
				this.$once("hook:beforeDestroy", () => {
					clearInterval(this.status);
					this.status = "";
				});
			}
		},
		success() {
            let smPhone=doEncrypt(this.dataPO.phone)
			this.$api.user
				.getVerificationCode({phone: smPhone})
				.then(() => {
					this.$message.success("已发送，请注意查收");
					let maxNum = 59;
					let oldCodeText = this.codeText;
					this.codeText = `${maxNum + 1}s重新发送`; //初始显示倒计时
					let codeCountDown = setInterval(() => {
						let countDownNum = maxNum--;
						this.codeText = `${countDownNum}s重新发送`;
						if (countDownNum == 0) {
							//倒计时结束
							this.codeText = oldCodeText;
							clearInterval(codeCountDown);
						}
					}, 1000);
				})
				.catch(err => {
					this.$message.error(err?.msg);
				}).finally(()=>{
					this.close()
				});
		},
		close() {
			this.isShow = false;
		},
		// 获取验证码
		getCode() {
			if (this.codeText !== "获取验证码") {
				return;
			}
			this.$refs.dataPO.validateField("phone", errorMessage => {
				if (!errorMessage) {
					if (!this.agreeChecked) {
						this.$message.error("请勾选用户协议");
						return;
					}
					this.isShow = true;
				}
			});
		},
		// 点击协议
		openAgree() {
			this.$refs.agreementModal.visible = true;
			// this.$refs.agreementModal.init();
		},
		// 打开免责声明
		openPrivacy() {
			this.$refs.privacyModal.visible = true;
		},
		// 点击登录
		submitForm() {
			this.$refs.dataPO.validate(valid => {
				if (valid) {
					if (!this.agreeChecked) {
						this.$message.error("请勾选用户协议");
						return;
					}
					const loading = this.$loading({
						lock: true,
						text: "登录中...",
						spinner: "el-icon-loading",
						background: "rgba(255, 255, 255, 0.3)",
						fullscreen: false,
					});
					let params = {...this.dataPO};
					params.deviceCode = this.$VERSION;
					this.$api.user
						.doLogin(params)
						.then(res => {
							this.$session.setLoginToken(res.data.access_token);
							// 获取用户信息
							this.$api.personalCenter
								.getInfo()
								.then(user => {
									this.$session.setUsers(user.data);
									this.$message.success("恭喜您，登录成功！");
									this.$router.push("/home");
								})
								.catch(msg => {
									if (msg?.msg) {
										this.$message.error(msg?.msg);
									}
								})
								.finally(() => {
									loading.close();
								});
						})
						.catch(err => {
							this.$message.error(err?.msg);
						})
						.finally(() => {
							loading.close();
						});
				} else {
					return false;
				}
			});
		},
		// 刷新二维码
		refreshQrCode() {
			if (this.status != "") {
				clearInterval(this.status);
				this.status = "";
			}
			this.key = new Date().getTime();
			this.qrCode = process.env.VUE_APP_BASE_URL + "/auth/api/refreshQrCode?key=" + this.key + "&deviceCode=" + this.$VERSION;
			let that = this;
			this.status = setInterval(function() {
				that.checkQrLoginStatus();
			}, 2000);
			this.$once("hook:beforeDestroy", () => {
				clearInterval(this.status);
				this.status = "";
			});
		},
		// 检查扫码登录状态
		checkQrLoginStatus() {
			console.log(this.key, "this.key");
			this.$api.user
				.getQrStatus({key: this.key})
				.then(res => {
					if (res.code == 200) {
						if (res.data.access_token) {
							this.$session.setLoginToken(res.data.access_token);
							// 获取用户信息
							this.$api.personalCenter
								.getInfo()
								.then(user => {
									this.$session.setUsers(user.data);
									this.$message.success("恭喜您，登录成功！");
									this.$router.push("/home");
								})
								.catch(msg => {
									if (msg?.msg) {
										this.$message.error(msg?.msg);
									}
								})
								.finally(() => {
									loading.close();
								});
						} else {
							if (res.data.needBidding) {
								this.$router.push("/home?state=" + res.data.state);
							}
						}
					}
				})
				.catch(err => {});
		},
	},
};
</script>

<style lang="less">
@import "./index.less";
</style>
